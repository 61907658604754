<template>
	<v-navigation-drawer floating app>
		<v-list>
			<v-list-item class="text-center">
				<v-img class="m-auto" max-width="145" max-height="30" src="../assets/kyano-order-logo-dark.svg"></v-img>
			</v-list-item>
		</v-list>

		<v-list shaped>
			<v-list-item-group color="secondary">
				<v-list-item v-for="item in sidebar" :key="item.title" link :to="item.to">
					<v-list-item-icon>
						<v-icon class="ml-2">{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
		<v-divider></v-divider>
		<div class="ma-5">
			<strong>Ordersysteem:</strong>
			<p>{{ shop.name }}</p>
		</div>
	</v-navigation-drawer>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters({ shop: "getshop" }),
	},
	data() {
		return {
			sidebar: [
				{ title: "Dashboard", icon: "fal fa-home-lg-alt", to: "/" },
				// { title: "Klanten", icon: "fal fa-user-friends", to: "/customers" },
				// {
				// 	title: "Abonnementen",
				// 	icon: "fal fa-repeat-alt",
				// 	to: "/subscriptions",
				// },
				{ title: "Producten", icon: "fal fa-box-full", to: "/products" },
				// { title: "Checkouts", icon: "fal fa-shopping-cart", to: "/checkouts" },
				// {
				// 	title: "Bedanktpagina's",
				// 	icon: "fal fa-thumbs-up",
				// 	to: "/thankyoupages",
				// },
				// { title: "Kortingscodes", icon: "fal fa-gift-card", to: "/coupons" },
				// { title: "Bestellingen", icon: "fal fa-piggy-bank", to: "/orders" },
			],
		};
	},
	methods: {
	}
};
</script>
