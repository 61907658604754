<template>
	<v-app-bar class="top-bar" color="#011a62" flat dark app>
		<v-breadcrumbs :items="this.breadcrumbs">
			<v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.href">
				{{ item.text }}
			</v-breadcrumbs-item>
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>

		<v-spacer></v-spacer>

		<!-- <v-btn v-if="shop().settings.mollietest" to="/settings/paymentmethodes" color="systemred" elevation="0" class="pa-5 rounded-none text-center">Test modus actief</v-btn> -->

		<!-- <v-menu offset-y min-width="400" content-class="rounded-lg elevation-1">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" dark v-bind="attrs" v-on="on">
					<v-icon>mdi-apps</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item class="text-center">
					<v-img class="m-auto" max-width="200" max-height="40" src="../assets/kyano-account-logo-dark.svg"></v-img>
				</v-list-item>
			</v-list>

			<v-list>
				<template v-for="item in products">
					<v-subheader class="subtitle-1 font-weight-bold" v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

					<v-list-item v-else :key="item.title">
						<div class="bg-light-btn">
							<v-icon>{{ item.icon }}</v-icon>
							<v-list-item-title v-html="item.title"></v-list-item-title>
						</div>
					</v-list-item>
				</template>
			</v-list>

			<div class="p-5 bg-white">
				<v-row align="center" justify="space-around">
					<v-btn elevation="0" fluid outline target href="https://kyanosite.nl">Alle Kyano Producten</v-btn>
				</v-row>
			</div>
		</v-menu> -->

		<v-menu offset-y min-width="400" max-width="400" content-class="rounded-lg elevation-1">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="white" class="mx-2" dark icon v-bind="attrs" v-on="on">
					<v-icon color="white">fal fa-user</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item>
					<v-list-item-avatar height="80" width="80" class="kyano_avatar" color="blue">
						<v-img v-if="user().profilepicture" :src="`${config.url.cdn}/usercontent/avatar/${user.id}.png`"></v-img>
						<span v-else class="white--text text-h5">{{ firstnamechar() }}</span>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="title">{{ getname() }}</v-list-item-title>
						<v-list-item-subtitle>{{ user().email }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>

			<v-list>
				<template v-for="(item, index) in items">
					<v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

					<v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

					<v-list-item v-else-if="item.signout" :key="item.title" @click="signout">
						<v-list-item-avatar>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title v-html="item.title"></v-list-item-title>
							<v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
						</v-list-item-content>
						<v-icon>fal fa-chevron-right</v-icon>
					</v-list-item>
					<v-list-item v-else :key="item.title" :href="item.href">
						<v-list-item-avatar>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title v-html="item.title"></v-list-item-title>
							<v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
						</v-list-item-content>
						<v-icon>fal fa-chevron-right</v-icon>
					</v-list-item>
				</template>
			</v-list>
			<v-divider></v-divider>
			<div class="p-5 bg-white">
				<v-row align="center" justify="space-around">
					<v-btn text elevation="0">Privacy beleid</v-btn>
					<v-divider vertical></v-divider>
					<v-btn text elevation="0" target href="https://kyanosite.nl">Gebruikersvoorwaarden</v-btn>
				</v-row>
			</div>
		</v-menu>
	</v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import * as cookies from 'js-cookie';
import config from '@/config';

export default {
	computed: {
		...mapGetters(['getshop', 'getuser']),
	},
	props: {
		breadcrumbs: Array,
	},
	data: () => ({
		products: [
			{
				header: "Kyano Orders",
			},
			{
				title: "Snelwebcenter",
				icon: "fal fa-user",
			},
			{
				title: "Snelwebcenter",
				icon: "fal fa-user",
			},
		],
		items: [
			// {
			// 	title: "Mijn Kyano account",
			// 	icon: "fal fa-user",
			// 	href: "https://kyanosite.nl",
			// },
			// { divider: true, inset: true },
			// {
			// 	title: "Instellingen",
			// 	icon: "fal fa-cog",
			// 	href: "/settings",
			// },
			// { divider: true, inset: true },
			// {
			// 	title: "Support",
			// 	icon: "fal fa-question-circle",
			// 	href: "https://kyanosite.nl",
			// },
			// { divider: true, inset: true },
			{
				signout: true,
				title: "Uitloggen",
				icon: "fal fa-sign-out",
			},
		],
	}),

	methods: {
		shop() {
			return this.getshop;
		},
		getname() {
			return `${this.getuser.firstname} ${this.getuser.insertion !== null ? this.getuser.insertion : ""} ${this.getuser.lastname}`
		},
		user() {
			return this.getuser
		},
		firstnamechar() {
			return this.getuser.firstname.charAt(0).toUpperCase()
		},
		signout() {
			// TODO delete token
			cookies.remove('kyano_session');
			cookies.remove('kyano_user');
			location.replace(config.url.main);
		}
	},
};
</script>
<style>
.top-bar .v-breadcrumbs__item {
	font-size: 18px;
	font-weight: inherit;
	color: #fff;
}

.top-bar .v-breadcrumbs__item.v-breadcrumbs__item--disabled {
	font-size: 22px !important;
	font-weight: bold !important;
	color: rgba(255, 255, 255) !important;
}
</style>
