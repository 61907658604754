<template>
	<div>
		<v-app-bar color="systemwhite" clipped-left app elevate-on-scroll fade-img-on-scroll>
			<v-app-bar-nav-icon color="primary" @click="menudrawer = !menudrawer"></v-app-bar-nav-icon>
			<a href="/">
				<v-img class="m-auto mr-10" max-width="165" width="165" max-height="30" src="../assets/kyano-order-logo-dark.svg"></v-img>
			</a>
			<v-breadcrumbs :items="this.breadcrumbs" class="d-none d-sm-flex">
				<v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.href">
					{{ item.text }}
				</v-breadcrumbs-item>
				<template v-slot:divider>
					<v-icon>mdi-chevron-right</v-icon>
				</template>
			</v-breadcrumbs>

			<v-spacer></v-spacer>
			<Topmenuapps />
			<!--if ingelogd-->
			<Topmenuprofile />
			<!--endif-->
		</v-app-bar>
		<v-navigation-drawer floating clipped class="pt-5" v-model="menudrawer" app>
			<v-list shaped>
				<v-list-item-group color="#34c759">
					<v-list-item v-for="item in sidebar" :key="item.title" link :to="item.to">
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
			<v-divider></v-divider>
			<div class="ma-5">
				<strong>Ordersysteem:</strong>
				<p>{{ shop.name }}</p>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import Topmenuapps from "./Topmenuapps.vue";
import Topmenuprofile from "./Topmenuprofile.vue";
import { mapGetters } from 'vuex';
import * as cookies from 'js-cookie';
import config from '@/config';


export default {
	components: { Topmenuprofile, Topmenuapps },
	computed: {
		...mapGetters({ shop: "getshop", user: "getuser" }),
	},
	props: {
		breadcrumbs: Array,
	},
	data: () => ({
		products: [
			{
				header: "Kyano Orders",
			},
			{
				title: "Snelwebcenter",
				icon: "fal fa-user",
			},
			{
				title: "Snelwebcenter",
				icon: "fal fa-user",
			},
		],
		items: [


			{
				signout: true,
				title: "Uitloggen",
				icon: "fal fa-sign-out",
			},
		],
		menudrawer: null,
		sidebar: [
			{ title: "Dashboard", icon: "fal fa-home-lg-alt", to: "/" },
			// { title: "Klanten", icon: "fal fa-user-friends", to: "/customers" },
			// {
			// 	title: "Abonnementen",
			// 	icon: "fal fa-repeat-alt",
			// 	to: "/subscriptions",
			// },
			{ title: "Producten", icon: "fal fa-box-full", to: "/products" },
			// { title: "Checkouts", icon: "fal fa-shopping-cart", to: "/checkouts" },
			// {
			// 	title: "Bedanktpagina's",
			// 	icon: "fal fa-thumbs-up",
			// 	to: "/thankyoupages",
			// },
			// { title: "Kortingscodes", icon: "fal fa-gift-card", to: "/coupons" },
			// { title: "Bestellingen", icon: "fal fa-piggy-bank", to: "/orders" },
		],
	}),
	methods: {
		getname() {
			return `${this.getuser.firstname} ${this.getuser.insertion !== null ? this.getuser.insertion : ""} ${this.getuser.lastname}`
		},
		firstnamechar() {
			return this.getuser.firstname.charAt(0).toUpperCase()
		},
		signout() {
			// TODO delete token
			cookies.remove('kyano_session');
			cookies.remove('kyano_user');
			location.replace(config.url.main);
		}
	},
};
</script>

<style>
.v-navigation-drawer .v-icon {
  width: 25px;
}

.v-toolbar__content .v-breadcrumbs__item {
	font-size: 18px;
	font-weight: inherit;
	color: #011a62
}

.v-toolbar__content .v-breadcrumbs__item.v-breadcrumbs__item--disabled {
	font-size: 22px !important;
	font-weight: bold !important;
	color: #011a62 !important;
}
</style>
